<template>
  <link rel="stylesheet" href="https://cdn.staticfile.org/font-awesome/4.7.0/css/font-awesome.css">
  <div class="bac">
    <div class="top">
      <div class="left">
        <div class="left_top">
          <p>{{ this.Info.typeSecond }}</p>
          <p>{{ this.Info.projectName }}</p>
        </div>
        <div class="left_bottom">
          <p>需求领域：{{ this.Info.typeSecond }}</p>
          <p>开始时间：{{ this.Info.beginTimeFormat }}</p>
          <p>项目预算：￥{{ this.Info.budget }}</p>
        </div>
      </div>
      <div class="right">

        <div class="right_line">
          <p>当前进度：</p>
          <div class="circle">
          </div>
          <i>-----------</i>
          <div class="circle">

          </div>
          <i>-----------</i>
          <div class="circle">

          </div>
          <i>-----------</i>
          <div class="circle">

          </div>
          <i>-----------</i>
          <div class="circle">

          </div>

        </div>

        <div class="line_content">
          <p>方案提交</p>
          <p>企业审核</p>
          <p>审核通过</p>
          <p>双方确认</p>
          <p>项目完成</p>

        </div>
      </div>
    </div>
    <div class="listBac">
      <div>
        <button @click="download()">点击下载合同模板</button>
      </div>
      <div>
        <div style="float:left">
          <el-upload  :on-change="fileChange" :show-file-list="false">
            <button @click="this.fileType = 'proposalFile'" :style="this.contractState.style">{{ this.contractState.message }}</button>
          </el-upload>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
let formData = new FormData();
export default {
  name: "CompanyContract",

  data() {
    return {
      ID: {
        pid: 1,
      },
      Info: '',
      contractState: {
        id:'',
        message:'',
        style:''
      },
    }
  },
  mounted() {
    this.getInfo();
    this.getContractState();
  },
  methods: {
    getInfo() {
      this.ID.pid = parseInt(this.$route.query.projectId);
      this.$http({
        method: 'post',
        url: '/getProjectMessageAndSchedule',
        data: this.ID
      }).then(res => {
        this.Info = res.data.projectMessage;
      })
    },
    fileChange (files, fileList) {
      formData.append('file',fileList[0].raw)
      this.submit();
      this.getContractState();
    },
    //等待后端填写状态
    getContractState(){
      this.$http({
        method: 'post',
        url: 'getContractProjectStatus',
        data: this.ID
      }).then(res =>{
        this.contractState.id = res.data;
        if (this.contractState.id === 0){
          this.contractState.message = '点击上传合同';
        }else if (this.contractState.id === 1){
          this.contractState.style = "background-color: #9e9e9e;";
          this.contractState.message = '合同审核中';
        }else if(this.contractState.id === 2){
          this.contractState.style = "background-color: #5cb85c;";
          this.contractState.message = '审核成功等待签订';
        }else if(this.contractState.id === 3){
          this.contractState.message = '审核未过重新上传';
        } else if(this.contractState.id === 4){
          this.contractState.style = "background-color: #5cb85c;";
          this.contractState.message = '合同已签订';
        }
      })

    },
    submit(){
      //将数据转化为formData格式
      formData.append('pid',this.ID.pid);

      this.$http({
        method: 'post',
        url: '/uploadContractProject',
        data: formData,
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }).then(res => {
        if (res.data.code === 1){
          this.$notify({
            title: '上传成功',
            message: '',
            type: 'success'
          });
        }
      })
    },
    download(){
      this.$http({
        method: 'post',
        url: '/downloadContractModel',
        data: '',
      }).then(res => {
        if (res.data.code === 1){
          window.location.href = res.data.url;
        }
      })

    }
  }
}
</script>

<style scoped>
.bac {
  width: 1920px;
  padding-left: 298px;
  padding-top: 50px;
  background-color: #f5f5f5;

}


.top {
  position: relative;
  width: 1462px;
  height: 330px;
  /*border-radius: 5px;*/
  /*background-color: pink;*/
  padding-top: 50px;
  /*margin: 0 auto 50px;*/
  background-color: #ffffff;
  /*box-shadow: 0 0 5px rgba(0,0,0,.2);*/
  margin-left: 70px;
  margin-bottom: 35px;

}

.top .label {
  position: absolute;
  width: 77px;
  height: 50px;
  top: 37px;
  right: 0px;
}

.top .label img {
  width: 100%;
  height: 100%;
}

.left {
  float: left;
  width: 298px;
  height: 244px;
  margin-left: 60px;
  border-right: 1px solid #a4a4a4;

}

.top .left_top p:nth-child(1) {
  height: 24px;
  color: #212121;
  font-size: 24px;
  margin-bottom: 20px;
}

.top .left_top p:nth-child(2) {
  height: 36px;
  color: #212121;
  font-size: 36px;
  margin-bottom: 65px;
}

.top .leftBottom {
  height: 86px;
  font-size: 18px;
  color: #a4a4a4;

}

.top .leftBottom p {
  height: 18px;
  margin-bottom: 19px;
}

.right {
  float: left;
  height: 244px;
  margin-left: 50px;

}

.right .itemButton {
  margin-bottom: 35px;
}

.right .itemButton button {
  width: 90px;
  height: 30px;
  font-size: 14px;
  background-color: #ff655f;
  margin-right: 10px;
  color: #ffffff;
  border-radius: 5px;
}

.right .right_line {
  height: 110px;
}

.right .right_line p {
  font-size: 18px;
  color: #212121;
  margin-bottom: 40px;
}

.right .right_line i {
  text-align: center;
  width: 97px;
}

.right .right_line div {
  float: left;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #ed404a;
}

.right .right_line div:nth-of-type(n+5) {
  background-color: grey;
}


.right .right_line i {
  float: left;
  height: 20px;
  color: #ed404a;
  letter-spacing: 2px;
  font-size: 15px;
  margin-top: -2px;
}

.right .right_line .circle:nth-child(2) {
  margin-left: 30px;
}

.right .line_content p {
  float: left;
  color: #212121;
  font-size: 18px;
  margin-right: 45px;
}

.right .line_content {
  height: 18px;
  margin-bottom: 30px;
}

.right .remind {
  position: relative;
  height: 14px;
}

.right .remind p {
  float: left;
  font-size: 14px;
  color: #adadad;
}

.right .remind i {
  float: left;
  margin-right: 5px;
}

.right .remind .eye {
  float: right;
  width: 45px;
  height: 18px;
  position: absolute;
  right: 120px;
}

.right .remind .eye span {
  height: 18px;
  float: left;
  font-size: 14px;
  line-height: 18px;
}


.right .remind .group {
  float: right;
  width: 45px;
  height: 18px;
  position: absolute;
  right: 60px;
}

.right .remind .group span {
  height: 18px;
  float: left;
  font-size: 14px;
  line-height: 18px;
}

.user_card_bottom {
  width: 1450px;
  height: 45px;
  background-color: #ffffff;
}

.user_card_bottom .real-name {
  float: left;
  width: 310px;
  height: 18px;
  margin-right: 643px;
  margin-left: 50px;
  border: 1px solid transparent;
  /*margin-top: 25px;*/
}

.user_card_bottom .real-name i {
  display: block;
  float: left;
  width: 10px;
  height: 10px;
  line-height: 10px;
  margin-right: 10px;
  border-radius: 50%;
  background-color: #ec414d;
  text-align: center;
  color: #ffffff;
  margin-top: 3px;
}


.user_card_bottom .real-name p {
  float: left;
  font-size: 12px;
  color: #acaeab;
}

.user_card_bottom number {
  float: right;
  height: 18px;
}

.user_card_bottom .number p {
  float: left;
  font-size: 16px;
  color: #212121;
  margin-right: 38px;
}

.user_card_bottom .number div {
  float: left;
  font-size: 16px;
  color: #ec414d;
  margin-right: 90px;
}

.listBac{
  position: relative;
  width: 1460px;
  height: 460px;
  margin-left: 70px;
  background-color: #ffffff;
  padding-top: 88px;
  padding-left: 78px;
}

.listBac button{
  width: 236px;
  height: 56px;
  font-size: 26px;
  text-align: center;
  background-color: #ff645f;
  color: #ffffff;
  border-radius: 6px;
  margin-right: 20px;
  margin-bottom: 70px;
}

.listBac i{
  height: 24px;
  font-size: 24px;
  color: #212121;
  margin-right: 44px;
}


</style>